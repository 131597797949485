import React from 'react';
import Navbar from '../components/Navbar';
import Devsecops from '../components/Devsecops';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <>
      <Navbar />
      <Devsecops />
      <Footer />
    </> 
  )
}

export default Home